<template>
  <div class="coianter">
    <div class="idteail">
      <div class="idtname">民用建筑能效标识申报指标情况表（居民建筑）</div>
      <div class="back" @click="goback()">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="tableform">
      <el-form ref="form" :model="project" label-width="180px">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目名称：">
                  {{ project.name }}
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="申报星级：">
                  <i
                    v-for="item in project.stars"
                    :key="item"
                    class="el-icon-star-on"
                  ></i>
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-form ref="formtable" :model="formtable" label-width="180px">
      <div class="engerytable">
        <table width="100%" border="1" cellspacing="0">
          <tbody>
            <!-- <tr>
      <th scope="col">项目名称</th>
      <th scope="col"  colspan="4"><input v-model="formtable.name" type="text"></th>
    </tr>
    <tr>
      <th scope="row">申报星级</th>
      <th scope="col"  colspan="4"><input v-model="formtable.name" type="text"></th>
    </tr> -->
            <tr>
              <th scope="row">申报指标</th>
              <!-- <td scope="col" colspan="2">
                <input
                  v-model="formtable.sbIndex"
                  type="text"
                  placeholder="请输入内容"
                />
              </td> -->
              <th scope="col" colspan="7">指标情况</th>
              <!-- <td scope="col" colspan="3">
                <input
                  v-model="formtable.sbCircu"
                  type="text"
                  placeholder="请输入内容"
                />
              </td> -->
            </tr>
            <tr>
              <th scope="row">相对节能率（%）</th>
              <td scope="col" colspan="7">{{ formtable.relateEnergy }}</td>
            </tr>
            <tr>
              <th scope="row">节能率</th>
              <td scope="col" colspan="2">
               {{formtable.buildEnergyRatio}}
              </td>
              <th scope="col" colspan="1">节能标准</th>
              <td scope="col" colspan="4">
                <div style="text-align: center">
                  <el-radio-group v-model="radio">
                    <el-radio :label="1">50%</el-radio>
                    <el-radio :label="2">65%</el-radio>
                    <el-radio :label="4">72%</el-radio>
                    <el-radio :label="3">75%</el-radio>
                  </el-radio-group>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">基础项</th>
              <td scope="col" colspan="7">
                经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                3964-2020标准要求，建筑节能率为{{
                  formtable.buildEnergyRatio
                }}%，测评合格。
              </td>
            </tr>
            <tr>
              <th scope="row">规定项</th>
              <td scope="col" colspan="7">
                经测评，该项目规定项{{
                  formtable.strip
                }}条参评，均满足《民用建筑能效测评标识标准》DB32/T
                3964-2020规定要求；
              </td>
            </tr>
            <tr>
              <th scope="row">选择项</th>
              <td scope="col" colspan="7">
                经测评，该项目选择项{{ formtable.selectStrip }}条参评，加{{
                  formtable.grades
                }}分；
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="6">建筑热工性能</th>
              <td>
                屋面保温<div style="color: #666"
                  >（示例：挤塑聚苯板(XPS)(ρ=25)（030级）(70.0mm)）</div
                >
              </td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.keepwarm"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
              <td>
                屋面传热系数[W/(m2·K)]<div style="color: #666"
                  >（提示：保留两位小数）</div
                >
              </td>
              <td colspan="4" style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.heattransfer"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
            </tr>
            <tr>
              <td>
                外墙保温<div style="color: #666"
                  >（示例：挤塑聚苯板(XPS)(ρ=25)（030级）(70.0mm)）</div
                >
              </td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.roofWarm"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
              <td>
                外墙传热系数[W/(m2·K)]
                <div style="color: #666">（提示：保留两位小数）</div>
              </td>
              <td colspan="4" style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.outHeat"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="4">
                外窗型材<div style="color: #666"
                  >（示例：铝合金窗（6 +16A+6））</div
                >
              </td>
              <td rowspan="4" style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.exterProfile"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
              <td rowspan="2">
                外窗传热系数[W/(m2·K)]<div style="color: #666">
                  （提示：保留两位小数，东/南/西/北）</div
                >
              </td>
              <td>东</td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.outEaast"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
              <td>南</td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.outSouth"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
            </tr>
            <tr>
              <td>西</td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.outWest"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
              <td>北</td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.outNorth"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="2" >
                遮阳系数SC<div style="color: #666"
                  >（提示：保留两位小数，东/南/西/北）</div
                >
              </td>
              <td>东</td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.scEaast"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
              <td>南</td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.scSouth"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
            </tr>
            <tr>
              <td>西</td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.scWest"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
              <td>北</td>
              <td style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.scNorth"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
            </tr>
               <tr>
                <th scope="row">空调冷热源
                  <div style="color: #666">（示例：冷水机组+燃气锅炉）</div>
                </th>
                <td colspan="7" style="background-color: #f4f4f4">
                  <textarea
                    v-model="formtable.cold_hot"
                    type="text"
                    placeholder="请输入内容"
                  />
                </td>
              </tr>
            <tr>
              <th scope="row">
                 是否采用生活热水节能（是/否）
                <div style="color: #666">
                  （提示：太阳能、地源热泵、空气源热泵提供生活热水）
                </div>
              </th>
              <td colspan="7" style="background-color:#f4f4f4">
               <el-select v-model="formtable.lifewater" placeholder="请选择活动区域">
                  <el-option :label="'是'" value="是"></el-option>
                  <el-option :label="'否'" value="否"></el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <th scope="row">单位建筑面积全年能耗（kWh/m2）</th>
              <td colspan="7" style="background-color:#f4f4f4">
                <textarea
                  v-model="formtable.yearEnergy"
                  type="text"
                  placeholder="请输入内容"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-form>
    <div class="comit">
      <!--      <div class="baocun">-->
      <!--        <el-button type="primary" @click="next_page">下一页</el-button>-->
      <!--      </div>-->
      <div class="baocun">
        <el-button type="primary" @click="pre_page">上一页</el-button>
      </div>
      <div class="baocun">
        <el-button type="primary" @click="saveProject(1)">保存</el-button>
      </div>
      <div class="baocun">
        <el-button type="primary" @click="saveProject()">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { editTableProject, projectTableInfo } from "../../../../api/project";

export default {
  data() {
    return {
      formtable: {},
      form: {},
      project: {},
       radio: "2",
      project_id: "",
      isSaved: false,
    };
  },

  mounted() {
    let project_id = this.$route.query.project_id;
    this.project_id = project_id;
    this.getInfo();
    this.getCache();
    this.onBlur();
  },

  methods: {
       // 返回
    goback(){
    this.$router.push('/user_mark/itemdeclare')
    },
     //设置失去焦点事件
    onBlur() {
      let that = this;
      let formDOM = document.getElementById("form");
      console.log(formDOM);
      let input = formDOM.getElementsByTagName("input");
      console.log(input);
      if (input && input.length > 0) {
        for (let i = 0; i < input.length; i++) {
          input[i].onblur = function () {
            that.saveCache();
          };
        }
      }
    },
    //自动保存填写的信息到缓存中
    saveCache() {
      if (this.toCache) {
        console.log("保存到缓存");
        if (this.form) {
          localStorage.setItem("itde_form", JSON.stringify(this.form));
        }
        if (this.fileList) {
          localStorage.setItem("itde_fileList", JSON.stringify(this.fileList));
        }
        if (this.photo) {
          localStorage.setItem("itde_photo", JSON.stringify(this.photo));
        }
      }
    },
    //清除缓存
    clearCache() {
      localStorage.removeItem("itde_form");
      localStorage.removeItem("itde_fileList");
      localStorage.removeItem("itde_photo");
    },

    //获得缓存中的信息
    getCache() {
      let form = localStorage.getItem("itde_form");
      if (form && form != "") {
        this.form = JSON.parse(form);
      }
      let fileList = localStorage.getItem("itde_fileList");
      if (fileList && fileList != "") {
        this.fileList = JSON.parse(fileList);
      }
      let photo = localStorage.getItem("itde_photo");
      if (photo && photo != "") {
        this.photo = JSON.parse(photo);
      }
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        that.formtable = that.form.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        let project = {
          name: that.form.name,
          building_area: that.form.building_area,
          build_company: that.form.build_company,
          address: that.form.address,
          weather: that.form.weather,
          design_company: that.form.design_company,
          manger_company: that.form.manger_company,
          work_build: that.form.work_build,
          number: that.form.number,
          stars: that.form.stars,
        };
        that.project = project;
        let jizu_total = that.form.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
      });
    },

    //上一页，保存
    pre_page() {
      let that = this;

      let params = {};
      params.jizu = this.jizu;
      params.jizu2 = this.jizu2;
      params.jizu3 = this.jizu3;
      params.jizu4 = this.jizu4;
      params.jizu5 = this.jizu5;
      params.jizu6 = this.jizu6;
      params.detail = this.formtable;
      params.project = this.project;
      params.id = this.project_id;
       params.radio=this.radio;
      params.project_id = this.project_id;
      params.token = this.$store.state.token;

      params.save = 1;

      params.files = this.form.files;
      if (params.files && params.files.length > 0) {
        for (let i = 0; i < params.files.length; i++) {
          params.files[i].name = params.files[i].file;
        }
      }
      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.$router.push({
            path: "./declareTableTwo?project_id=" + that.project_id,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //保存项目
    saveProject(save) {

      if (this.isSaved){
        this.$message.warning("已经提交过了，请勿重复提交");
        return false;
      }
      this.isSaved = true;

      console.log(save);
      let that = this;

      let params = {};
      params.jizu = this.jizu;
      params.jizu2 = this.jizu2;
      params.jizu3 = this.jizu3;
      params.jizu4 = this.jizu4;
      params.jizu5 = this.jizu5;
      params.jizu6 = this.jizu6;
      params.detail = this.formtable;
      params.project = this.project;
      params.id = this.project_id;
       params.radio=this.radio;
      params.project_id = this.project_id;
      params.token = this.$store.state.token;
      if(save==1){
        params.save = save;
      }

      params.files = this.form.files;
      // if (params.status != 15 && params.status != 17) {
      //   params.save = 1;
      // }
      if (params.files && params.files.length > 0) {
        for (let i = 0; i < params.files.length; i++) {
          params.files[i].name = params.files[i].file;
        }
      }

      editTableProject(params)
        .then((res) => {
          console.log(res);
          that.getInfo();
          if (save == "1") {
            that.$message.success(res.message || "保存成功");
          } else {
            that.$message.success("提交成功");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
textarea {
  width: 99%;
  height: 100%;
  border:none;
  text-align: center;
  line-height: 40px;
  overflow: visible;
  font-size: 14px;
  word-break: break-all;
  background-color: rgb(255,255,255,0.1);
}
textarea::-webkit-scrollbar{
  display: none;
}
textarea:focus-visible{
  border: none;
}
/deep/.el-select{
      width: 100%;
}
/deep/.el-input--suffix .el-input__inner{
      text-align: center;
      background-color: rgb(255,255,255,0.1);
}
/deep/.tableform .el-form-item {
  margin-bottom: 0;
}
/deep/input::placeholder{
  color: #66b1ff;
}
/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}
/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}
/deep/ .minyong .minyong_select input {
  width: 335px;
}
/deep/.minyong .el-input__suffix {
  right: -75px;
}

/deep/ .el-input {
  width: 100%;
}
</style>
<style lang="scss" scoped>
th {
  height: 40px;
}
.el-select-dropdown__item{
  text-align: center;
}
input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}

.engerytable {
  margin: 20px;
}

.coianter {
  background-color: #ffffff;
  // height: 100%;
}

.idteail {
  margin: 4px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #f4f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
    cursor: pointer;
  }
}

.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .geziright {
    // margin-left: 20px;
  }

  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
  }
}

.tableform {
  margin: 16px 100px;
}

.comit {
  padding: 16px 0;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .baocun {
    margin: 0 25px;
    display: flex;
    flex-direction: row;
  }
}

td {
  height: 40px;
  text-align: center;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
}

.zengjia {
  margin: 10px 0;
}
</style>
